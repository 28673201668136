@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fjalla+One&family=Hind+Madurai&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fjalla+One&family=Hind+Madurai:wght@300;400&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Heebo:wght@100..900&family=PT+Serif:wght@400;700&display=swap');

@media screen and (min-width: 1024px) {
    .content-wrapper-brand-img{
        width: 100%;
        margin: 0 auto;
    }
    .content-wrapper{
        width: 65%;
        margin: 0 auto;
    }
    .content-wrapper-menu{
        width: 65%;
        margin: 0 auto;
        background-color: black;
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px){
    .box-b-txt{
        .box-container-txt{
            font-size: 28px;
            padding-top: 10px;
        }
        .box-container-txt-sub{
            font-size: 14px;
        }
        .btn-container{
            font-size: 14px;
        }
        .box-container-txt-sub-sub{
            width: 70%;
            padding-top: 10px;
        }
    }
}

@media screen and (max-width: 768px){
    .box-b-txt{
        .box-container-txt{
            font-size: 28px;
            padding-top: 10px;
        }
        .box-container-txt-sub{
            font-size: 14px;
        }
        .btn-container{
            font-size: 14px;
        }
        .box-container-txt-sub-sub{
            width: 90%;
            padding-top: 10px;
        }
    }
    .content-box{
        .main-text{
            font-size: 12px;
        }
        .heading-title{
            font-size: 22px;
        }   
        .size-main{
            font-size: 28px;
            margin-top: -10px;
        }
        .heading-title-sub{
            font-size: 14px;
            width: 80%;
            margin: 0 auto;
            line-height:1.2;
        }
    }
    .content-wrapper-brand-img{
        .content-img-book{
            padding: 50px;
            .txt-book{
                font-size: 28px;
            }
            .txt-book-m{
                font-size: 20px;
                line-height: 1em;
                letter-spacing: 0px;
            }
        }
    }
    .content-width{
        .content-nano-left{
            width: 85%;
            margin-bottom: 15px;
            margin-top: 35px;
            .text-main-nano{
                font-size: 13px;
            }
            .text-nano{
                font-size: 12px;
            }
        }
    }
    .content-width{
        .content-nano-right{
            width: 85%;
            float: right;
            margin-bottom: 15px;
            margin-top: 25px;
            .text-main-nano{
                font-size: 13px;
            }
            .text-nano{
                font-size: 12px;
            }
        }
    }
    .img-car-nano-m{
        .img-car-nano{
            margin: 35px 0px 35px 0px;
            width: 85%;
        }
    }
}

p {
    margin-top: 0;
    margin-bottom: 0;
}
.margin-20{
    margin: 20px;
}
.m-top-bottom-25{
    margin-top: 25px;
    margin-bottom: 25px;
}
.box-container {
    position: relative; /* Set kontainer sebagai referensi untuk posisi absolut anaknya */
}

.box-b-txt {
    position: absolute;
    top: 50px; /* Ubah angka ini sesuai kebutuhan */
    left: 0;
    width: 100%;
}
.box-container{
    width: 100%;
    height: 750px;
    margin-top: 95px;
    padding-top: 25px;
    background-color: grey;
    place-items: center;
    align-content: center
}
.box-container-txt{
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align:center;
    color: #FFFFFF;
    font-size: 55px;
}
.txt-book{
    color: red;
    font-family: "Bebas Neue", Sans-serif;
    font-size: 55px;
    font-weight: 500;
    text-align:center;
}
.txt-book-m{
    color: #FFFFFF;
    font-family: "Bebas Neue", Sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 1.1px;
    text-align:center;
}
.box-container-txt-sub{
    text-align:center;
    color: #FFFFFF;
    font-size: 16px;
}
.box-container-txt-sub-sub{
    width: 50%;
    margin: 0 auto;
    text-align:center;
    color: #aeaeae;
    font-size: 14px;
}
.btn-container{
    color: #FFFFFF;
    background-color: red !important;
    border-color: red !important;
    font-size: 16px;
    border-radius: 20px;
}
.box-container-img{
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-image: url("../../../assets/images/cover-topcoat.jpg");
}
.box-service-instagram{
    width: 100%;
}
.tl-box-service-instagram{
    font-weight: 600;
    position: relative;
    line-height: 30px;
}
.element.style {
    text-align: center;
    color: #fff;
    font-size: 0.5rem !important;
}
.css-6kluu6{
    font-size: 0.5rem !important;
    box-sizing: border-box;
    margin: 6px 0px 0px;
    min-width: 0px;
}
.box-menu{
    background-color: black;
    width: 100%;
    padding: 15px 0px 15px 0px;
}
.box-img-menu{
    background-color: grey;
    margin: 15px 15px 15px 15px;
}
.img-menu{
    width: 100%;
}
.icon-whatsapp {
    position: fixed;
    right: 25px;
    bottom: 25px;
    height: 50px;
    width: 50px;
    z-index: 1;
}

.red{
    color: #ec1c24;
}
.heading-title{
    font-family: 'Bebas Neue', sans-serif;
    font-size: 38px;
}
.heading-title-sub{
    font-family: 'Hind Madurai', sans-serif;
    font-size: 20px;
    color: rgb(160, 160, 160);
}
.size-main{
    font-size: 58px;
    margin-top: -20px;
}
.center{
    text-align: center;
}
.main-text{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
}

.content{
    margin: 50px 0px 50px 0px;
}
.content-box{
    padding: 10px;
}

.content-img-product{
    // margin-top: 25px;
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.content-img-ilustrasi{
    margin-top: 25px;
    width: 100%;
    height: 100%;
}

.content-img-book{
    width: 100%;
    height: 200px;
    padding: 30PX;
    background-size: cover;
    background-position: center center;
    background-image: url("../../../assets/images/car-book.jpg");
}

.content-qa{
    width: 100%;
    height: 90%;
    padding: 25px;
    margin: 25px 0px 25px 0px;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.222) 0px 8px 24px;
}

.text-qa{
    font-size: 16px;
    font-weight: bold;
}
.text-qa-m{
    margin-top: 15px;
    font-size: 14px;
    color: grey;
}
.content-testi{
    height: 200px;
    padding: 20px;
    margin: 20px;
    width: 90%;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.222) 0px 0px 10px;
}
.content-testi-name{
    font-size: 16px;
    font-weight: bold;
}
.content-testi-text{
    padding-top: 10px;
    font-size: 14px;
    font-style: italic;
    color: gray;
}
.content-testi-star{
    color: rgb(255, 111, 0);
}

.img-profile{
    width: 45px;
    border-radius: 25px;
}

.content-left{
    float: left;
    margin-right: 10px;
}

.mrv-padding{
    padding: 0;
}


.content-nano-left{
    width: 100%;
    height: 60px;
    margin-bottom: 35px;
    margin-top: 35px;
    .content-left-nano{
        border: 1px solid rgb(255, 255, 255);
        width: 20%;
        height: 100%;
        float: right;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center; 
        font-size: 35px;
        color: #670900;
        background-color: white;
    }
    
    .content-right-nano{
        padding: 0px 15px 0px 5px;
        width: 80%;
        height: 100%;
        float: left;
        line-height: 1.2;
    }
    .text-main-nano{
        text-align: right;
    }
    .text-nano{
        text-align: right;
        padding-left: 25%;
    }
}

.content-nano-right{
    width: 100%;
    height: 60px;
    margin-bottom: 35px;
    margin-top: 35px;
    .content-left-nano{
        border: 1px solid rgb(255, 255, 255);
        width: 20%;
        height: 100%;
        float: left;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center; 
        font-size: 35px;
        color: #670900;
        background-color: white;
    }
    .content-right-nano{
        padding: 0px 5px 0px 15px;
        width: 80%;
        height: 100%;
        float: right;
        line-height: 1.2;
    }
    .text-main-nano{
        text-align: left;
    }
    .text-nano{
        text-align: left;
        padding-right: 25%;
    }
}

.text-main-nano{
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 5px;
}

.text-nano{
    font-size: 12px;
    font-weight: 500;
    color: #818181;
}

.img-car-nano-m{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img-car-nano{
    width: 100%;
}

.before-after-slider{
    .before-after-slider__delimiter-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
        background-color: #ff0000;
        border: 3px solid rgb(180, 180, 180);
        box-shadow: rgb(151 151 151) 0px 0px 10px;
    }
    .before-after-slider__delimiter {
        position: absolute;
        height: 100%;
        width: 3px;
        box-shadow: rgb(181 181 181) 0px 0px 5px;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.gray-color{
    padding: 50px 0px 50px 0px;
    background-color: #111111;
    color: white;
}

.add-padding{
    padding: 10px;
}

.btn-content{
    padding-bottom: 20px;
}